import React, { useRef, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import '../styles/map-view.scss';

import data from '../data/cameras';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
 
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

//import loading from '../images/loading-wheel.png'

const MapView = forwardRef((props, ref) => {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lat, setLat] = useState(52.178);
    const [lng, setLng] = useState(-0.865);    
    const [zoom, setZoom] = useState(0);

    useEffect(() => {

        if(!props.isLoading && map.current) {
            map.current.flyTo({center: [lng, lat], zoom: 6.5});
        }

        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/willl-mother/clppx01d6011o01pjfjf98t6q',
        // style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: zoom
        });

        map.current.on('style.load', () => {
            addMarkersToMap();
            props.setSectionLoaded(true);
        });

    }, [props.isLoading]);

    useImperativeHandle(ref, () => ({
        map,        
    }));

    const addMarkersToMap = () => {

        // add markers to map
        for (const camera of data.cameras) {

            const variation = Math.floor(Math.random() * 3)
            const ip = (Math.floor(Math.random() * 255) + 1)+"."+(Math.floor(Math.random() * 255))+"."+(Math.floor(Math.random() * 255))+"."+(Math.floor(Math.random() * 255));
            // create a HTML element for each feature
            const el = document.createElement('div');
            el.className = `marker marker-${variation}`;
            
            const videoEmbed = camera.videoURL.includes('tfl.') ? true : false;

            const htmlString = videoEmbed ? `<a href='${camera.videoURL}' target='_blank'>
                                                <h3>${camera.name}<br/>${ip}</h3>
                                                <video src='${camera.videoURL}' autoplay muted playsinline loop / >
                                            </a>` : 
                                            `<a href='${camera.videoURL}' target='_blank'>
                                                <h3>${camera.name}<br/>${ip}</h3>
                                                <img src='${camera.imageURL}' alt='' / >
                                            </a>`
            // make a marker for each feature and add it to the map
            const marker = new mapboxgl.Marker(el)
                .setLngLat([camera.location.lng, camera.location.lat])

                .addTo(map.current);

            marker.getElement().addEventListener('click', () => {
                // marker.setPopup(
                //     new mapboxgl.Popup({ offset: 25 }) // add popups
                //     .setHTML(
                //     `<a href='${camera.videoURL}' target='_blank'>
                //         <h3>${camera.name}</h3>
                //         <img src='${camera.imageURL}' / >
                //     </a>`
                //     )
                // )
                marker.setPopup(
                    new mapboxgl.Popup({ 
                        offset: 25,
                        className: `popup-${variation}`
                     }) // add popups
                    .setHTML(htmlString)
                )                
            });            
        }        
    };

    return (
        <div className='map-view-container' data-clickable>
            <div ref={mapContainer} className="map-container" />
        </div>
    );
});

export default MapView