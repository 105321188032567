import React from 'react';
import '../styles/disclaimer-view.scss';

const DisclaimerView = ({}) => {

    return (
        <div className='disclaimer-view-container' data-clickable>

            <h1>Website Terms of Use</h1>
                
                <h3>1.  Introduction</h3>

                <p>1.1  Welcome to our website (<b>“Website”</b>) which is operated by PBPMLL0012 LIMITED (<b>“we” “us” “our”</b>). whose registered office is at Biscuit Building, 10 Redchurch Street, London, E2 7DD.</p>

                <p>1.2  Access to and use of this Website is subject to the following terms, conditions and notices (the <b>“Terms“</b>). Please read these Terms carefully and alongside our <a href='/privacy'>Privacy Policy.</a></p>
            
                <p>1.3  Please note: Clause 11.2 of these Terms contains an arbitration clause and class action waiver that applies to all claims brought against us in the United States. Please read it carefully as it affects how disputes with us are resolved.</p>

                <p>1.4  By using our Website you agree to be legally bound by these Terms. If you do not agree to accept these Terms, you should not use our Website.</p>

                <h3>2.  Access and use of the website</h3>

                <p>2.1  Access to our Website is provided on a temporary basis for your personal and non-commercial use and we reserve the right to suspend, withdraw, discontinue or change any part of our Website, including the availability of any content, without notice. We will not be liable to you if for any reason our Website is unavailable at any time or for any period.</p>
            
                <p>2.2  The content on our Website is provided for information only. Although we make reasonable efforts to update the information on our Website, we make no representations, warranties or guarantees, whether express or implied, that the content on our Website is accurate, complete or up-to-date.</p>

                <p>2.3  In accessing any part of our Website, you agree not to:<br /><br />
                    use our Website in such a way that disrupts, interferes with or restricts the use of our Website by other users;<br /><br />
                    use our Website for any activities which breach any laws or regulations or infringe any third party rights;<br /><br />
                    use the personal information of another person in order to access or use our Website;<br /><br />
                    remove, obscure, or alter any copyright notices, trademarks, or other proprietary rights notices of ours or any third party;<br /><br />
                    decompile, reverse engineer, disassemble, copy or adapt any software or other code or scripts forming part of our Website; and<br /><br />
                    transmit any viruses, Trojans, worms, logic bombs or other material which is malicious or technologically harmful through our Website.
                    </p>

                <h3>3.  Our content</h3>
                <p>3.1  Our content, trademarks, copyright, patents, logos, domain names and other related intellectual property rights or other features of our brand or our clients’ brand(s) belong to us or to our clients or licensors. Your use of our Website does not grant you any rights in our and/or our licensors intellectual property whether for commercial or non-commercial use. By using the Website, you agree not to copy, distribute, modify or make derivative works of any materials on the Website without the prior written consent of the owner of such materials.</p>

                <p>3.2  We do not accept any unsolicited ideas to this Website including without limitation suggestions about advertising or promotions, or merchandising of any products, additions to our services, or changes in methods of doing business. We may already be working on or may in the future work on a similar idea. This policy eliminates concerns about ownership of such ideas. If, notwithstanding this policy, you submit an unsolicited idea to this Website, you understand and acknowledge that such idea is not submitted in confidence and we assume no obligation, expressed or implied, by considering it. You further understand that we shall exclusively own all known or hereafter existing rights to the idea everywhere in the world, and that such idea is hereby irrevocably assigned to us. Without limiting the foregoing, to the extent any such assignment is deemed unenforceable, and or to the extent necessary for us to utilise your submission, you hereby grant to us an irrevocable, perpetual, world-wide license to use the idea and any associated intellectual property in any manner, in any medium now known or hereafter developed, without compensation to you.</p>

                <h3>4.  Third party websites</h3>
                <p>4.1  Our Website may include links to third party websites which are controlled and maintained by others. These links are provided for information and convenience and we have no control over and cannot therefore accept responsibility or liability for any content on any linked third party website. We do not endorse any linked website.</p>

                <p>4.2  Your use of any third party internet website will be subject to that website’s own terms of use, rules and policies, and not these Terms. It is your responsibility to become familiar and comply with such third party website terms and conditions and any other policies of such websites.</p>

                <h3>5.  Privacy</h3>

                <p>We may collect certain personal and analytical information from you, among other ways, through the Website. We will handle such information in accordance with our <a href='/privacy'>Privacy Policy.</a></p>

                <h3>6.  Disclaimer</h3>

                <p><b>The website and all content are provided “as is” and without any warranty or representation of any kind, whether express or implied (including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose). Without limiting the generality of the immediately preceding sentence, to the fullest extent permissible by law, we do not warrant or represent that the website or any content on the website: (a) will always be available for use, (b) are free from spyware, malware, adware, viruses, worms or other malicious code or other harmful components, (c) will meet your requirements, (d) do not infringe the intellectual property rights of a third party, or (e) are error-free or that any defects therein will be corrected.</b></p>

                <h3>7.  Our liability to you</h3>

                <p><b>7.1  Subject to clause 7.3, to the maximum extent permitted by law your sole and only remedy for any problems, issues or concerns arising from the use of our website is to stop using our website.</b></p>

                <p><b>7.2  Without prejudice to the generality of clause 7.1, you agree that; (1) in the event that clause 7.1 is not binding on you, our maximum liability shall be limited to direct damages only up to a maximum sum of £100 sterling; and (2) we shall not have any liability for incidental, special, indirect, consequential or other damages whatsoever (including, but not limited to, damages for loss of profits, loss of data, or computer crashes or other denials of services) arising from or related to your use of, or inability to use, the website site, or any content thereon, however caused, regardless of the theory of liability (including, but not limited to, breach of contract, tort, (including negligence) or other), and even if we knew, or should have known, of the possibility of such damages.</b></p>

                <p>7.3  We do not exclude our liability (if any) to you for: (1) personal injury or death resulting from our negligence; (2) fraud or fraudulent misrepresentation; or (3) any matter for which it would be illegal for us to exclude, or to attempt to exclude, our liability.</p>

                <h3>8.  Your liability to us</h3>

                <p>You agree to indemnify and hold us, our subsidiaries, and affiliates, and their respective officers, agents, partners and employees, harmless from any loss, liability, demand, claim or legal proceedings brought or threatened arising out of or in connection with: (1) your misuse of this Website; (2) breach of these Terms; and/or (3) any violation by you of applicable laws.</p>

                <h3>9.  Information provided by you</h3>
                <p>You grant to us a non-exclusive, perpetual and fully-paid license to copy, distribute, modify, and create derivative works of all information and other content submitted by you to us through the Website. All information provided to you through the Website must be accurate and complete in all respects, unless by its nature such information is not intended to be accurate or complete.</p>

                <h3>10.  General provisions and Jurisdiction</h3>
                <p>10.1  If any provision of these Terms is found to be invalid for any reason, the invalidity of that provision will not affect the remaining provisions of these Terms, which will remain in full force and effect.</p>

                <p>10.2  We may amend these Terms at any time, so please ensure that you check them frequently. By continuing to use any of our Website after changes are made, you are accepting those changes and will be bound by them.</p>

                <p>10.3  These Terms constitute the entire agreement between us and you regarding use of the Website.</p>

                <p>10.4  Failure by either of us to exercise any right or remedy under these Terms does not constitute a waiver of that right or remedy.</p>

                <h3>11.  Governing law and jurisdiction</h3>

                <p><b>11.1  If you are not a resident of the United States:</b> Any dispute or claim arising out of or in connection with our Website and/or these Terms (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of England and Wales and will be subject to the exclusive jurisdiction of the English courts. This does not affect your statutory rights in your home jurisdiction.</p>

                <p><b>11.2  If you are a resident of the United States: </b>The laws of the State of New York shall govern these Terms. While we will make reasonable efforts to resolve any disagreements you may have with us, if these efforts fail you agree that all claims, disputes or controversies against us arising out of these Terms or any services provided by us (“Claims”) shall be exclusively submitted to binding arbitration (except for matters that may be taken to small claims court), no matter what legal theory they are based on or what remedy (damages, or injunctive or declaratory relief) they seek. This includes Claims based on contract, tort (including intentional tort), fraud, agency, your or our negligence, statutory or regulatory provisions, or any other sources of law; Claims made as counterclaims, cross-claims, third- party claims, interpleaders or otherwise; and Claims made independently or with other claims. The party filing arbitration must submit Claims to the American Arbitration Association and follow its rules and procedures for initiating and pursuing arbitration. Any arbitration hearing that you attend will be held at a place chosen by the American Arbitration Association in the same city as the U.S. District Court closest to your then current residential address, or at some other place to which you and we agree in writing, and the arbitrator shall apply New York law consistent with the Federal Arbitration Act. <b>You shall not be entitled to join or consolidate claims in arbitration by or against other users or to arbitrate any claim as a representative or member of a class or in a private attorney general capacity. </b>You may obtain copies of the current rules, and forms and instructions for initiating arbitration by contacting the American Arbitration Association at using the contact information noted below.<br /><br />
                American Arbitration Association<br /><br />
                800-778-7879 (toll-free)<br /><br />
                Website: <a href='https://www.adr.org'>www.adr.org</a>
                </p>

                <p>11.3  A single, neutral arbitrator will resolve Claims. The arbitrator will be either a lawyer with at least ten (10) years’ experience or a retired or former judge, selected in accordance with the rules of the American Arbitration Association. The arbitration will follow the procedures and rules of the American Arbitration Association which are in effect on the date the arbitration is filed unless those procedures and rules are inconsistent with these Terms, in which case these Terms will prevail. Those procedures and rules may limit the discovery available to you or us. The arbitrator will take reasonable steps to protect your customer account information and other confidential information if requested to do so by you or us. Each party to the arbitration will bear the expense of that party’s attorneys, experts, and witnesses, and other expenses, regardless of which party prevails, but a party may recover any or all expenses from another party if the arbitrator, applying applicable law, so determines. The arbitrator’s award is final and binding on the parties.</p>

                <p><b>11.4  Any claim you have must be commenced within one (1) year after the date the claim arises.  As noted above, you and we hereby voluntarily and knowingly waive any right either may have to a jury trial.</b></p>

                <p>11.5  We will not choose to arbitrate any Claim you bring in small claims court. However, if such a Claim is determined by the court to be outside its jurisdiction, the parties agree that the dispute shall then be submitted to arbitration.</p>

                <h2>OTHER INFORMATION</h2>

                <p>In these Terms, the following words and expressions have the following meanings:<br /><br />
                    <b>Our content</b> means any text, files, images, photos, graphics, video, sounds, musical works or any other materials that we post on or through the Website.<br /><br />
                    <b>Third party content</b> any text, files, images, photos, graphics, video, sounds, musical works or any other materials posted on or through the Website by our third party partners.<br /><br />
                    <b>You, your, yours</b> means you as a user of any Website that hosts these Terms.<br /><br />
                    <b>Last updated: December 2023</b></p>

            <h1>PRIVACY POLICY</h1>
                <p><b>Last updated December 28, 2023</b></p>
                <p>This privacy notice for PBPMLL0012 LIMITED (<b>'we'</b>, <b>'us'</b>, or <b>'our'</b>), describes how and why we might collect, store, use, and/or share (<b>'process'</b>) your information when you use our services (<b>'Services'</b>), and that we may obtain through you interacting with us including any marketing, or events.</p>
                <p><b>Questions or concerns?</b>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:account@PBPMLL0012.com">account@PBPMLL0012.com.</a></p>
                <h2>SUMMARY OF KEY POINTS</h2>

                <p><i><b>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.</b></i></p>
                <p><b>What personal information do we process?</b> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.</p>
                <p><b>Do we process any sensitive personal information?</b> We do not process sensitive personal information.</p>
                <p><b>Do we receive any information from third parties?</b> We do not receive any information from third parties.</p>
                <p><b>How do we process your information?</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.</p>
                <p><b>In what situations and with which parties do we share personal information?</b> We may share information in specific situations and with specific third parties.</p>
                <p><b>How do we keep your information safe?</b> We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</p>
                <p><b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.</p>
                <p><b>How do you exercise your rights?</b> The easiest way to exercise your rights is by visiting <a href="https://antslive.com">https://antslive.com</a>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>
                <p>Want to learn more about what we do with any information we collect? Review the privacy notice in full.</p>

                <h2>TABLE OF CONTENTS</h2>              

                <ol>
                    <li>WHAT INFORMATION DO WE COLLECT?</li>
                    <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
                    <li>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</li>
                    <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
                    <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
                    <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
                    <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
                    <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
                    <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
                    <li>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
                    <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
                    <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
                    <li>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
                </ol>

                <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>

                <p><strong>Personal information you disclose to us</strong></p>

                <p><strong><em>In Short: </em></strong><em>We collect personal information that you provide to us.</em></p>

                <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>

                <p><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>

                <ul>
                <li>email addresses</li>
                </ul>

                <ul>
                <li>passwords</li>
                </ul>

                <p><strong>Sensitive Information.</strong> We do not process sensitive information.</p>

                <p><strong>Application Data.</strong> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</p>

                <ul>
                <li><em>Mobile Device Data.</em> We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device&rsquo;s operating system or platform, the type of mobile device you use, your mobile device&rsquo;s unique device ID, and information about the features of our application(s) you accessed.</li>
                </ul>

                <ul>
                <li><em>Push Notifications.</em> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.</li>
                </ul>

                <p>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</p>

                <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

                <p><strong>Information automatically collected</strong></p>

                <p><strong><em>In Short: </em></strong><em>Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device characteristics &mdash; is collected automatically when you visit our Services.</em></p>

                <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>

                <p>The information we collect includes:</p>

                <ul>
                <li><em>Device Data.</em> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
                </ul>

                <ul>
                <li><em>Location Data.</em> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
                </ul>

                <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>

                <p><strong><em>In Short: </em></strong><em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em></p>

                <p><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong></p>

                <ul>
                <li><strong>Marketing communications: To notify you about our product availability.</strong></li>
                </ul>

                <ul>
                <li><strong>To save or protect an individual's vital interest.</strong> We may process your information when necessary to save or protect an individual&rsquo;s vital interest, such as to prevent harm.</li>
                </ul>

                <h2>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>

                <p><strong><em>In Short: </em></strong><em>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.</em></p>

                <p><u><strong><em>If you are located in the EU or UK, this section applies to you.</em></strong></u></p>

                <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</p>

                <ul>
                <li><strong>Contract. </strong>We may process your information where it is necessary for us to perform a contract with you or deliver a service you have requested.</li>
                <li><strong>Legitimate Interest. </strong>We may process your information to meet a legitimate interest such as responding to your enquiry or other interaction with us, subject to your privacy rights (see section 8).</li>
                <li><strong>Consent. </strong>We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time.</li>
                </ul>

                <ul>
                <li><strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</li>
                </ul>

                <ul>
                <li><strong>Vital Interests.</strong> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</li>
                </ul>

                <h2>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>

                <p><strong><em>In Short:</em></strong><em> We may share information in specific situations described in this section and/or with the following third parties.</em></p>

                <p>We may need to share your personal information in the following situations:</p>

                <ul>
                <li><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                </ul>

                <h2>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

                <p><strong><em>In Short: </em></strong><em>We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</em></p>

                <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>

                <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>

                <h2>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

                <p><strong><em>In Short: </em></strong><em>We aim to protect your personal information through a system of organisational and technical security measures.</em></p>

                <p>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>

                <h2>7. DO WE COLLECT INFORMATION FROM MINORS?</h2>

                <p><strong><em>In Short:</em></strong><em> We do not knowingly collect data from or market to children under 18 years of age.</em></p>

                <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <a href="mailto:account@PBPMLL0012.com">account@PBPMLL0012.com</a>.</p>

                <h2>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>

                <p><strong><em>In Short:</em></strong><em> In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</em></p>

                <p>In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.</p>

                <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>

                <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank" rel="noopener">Member State data protection authority</a> or <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/" target="_blank" rel="noopener">UK data protection authority</a>.</p>

                <p>If you are located in Switzerland, you may contact the <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank" rel="noopener">Federal Data Protection and Information Commissioner</a>.</p>

                <p><u><strong>Withdrawing your consent:</strong></u> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.</p>

                <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>

                <p><strong>Account Information</strong></p>

                <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>

                <ul>
                <li>Contact us using the contact information provided.</li>
                </ul>

                <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>

                <p>If you have questions or comments about your privacy rights, you may email us at <a href="mailto:account@PBPMLL0012.com">account@PBPMLL0012.com</a>.</p>

                <h2>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

                <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>

                <h2>10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

                <p><strong><em>In Short: </em></strong><em>If you are a resident of California, Virginia, Colorado, Connecticut, you are granted specific rights regarding access to your personal information.</em></p>

                <p><strong>What categories of personal information do we collect?</strong></p>

                <p>We have collected the following categories of personal information in the past twelve (12) months:</p>

                <div>
                <table>
                <tbody>
                <tr>
                <td><strong>Category</strong></td>
                <td><strong>Examples</strong></td>
                <td><strong>Collected</strong></td>
                </tr>
                <tr>
                <td>A. Identifiers</td>
                <td>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</td>
                <td>YES</td>
                </tr>
                <tr>
                <td>B. Protected classification characteristics under state or federal law</td>
                <td>Gender and date of birth</td>
                <td>NO</td>
                </tr>
                <tr>
                <td>C. Commercial information</td>
                <td>Transaction information, purchase history, financial details, and payment information</td>
                <td>NO</td>
                </tr>
                <tr>
                <td>D. Biometric information</td>
                <td>Fingerprints and voiceprints</td>
                <td>NO</td>
                </tr>
                <tr>
                <td>E. Internet or other similar network activity</td>
                <td>Browsing history, search history, online behaviour, interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
                <td>NO</td>
                </tr>
                <tr>
                <td>F. Geolocation data</td>
                <td>Device location</td>
                <td>YES</td>
                </tr>
                <tr>
                <td>G. Audio, electronic, visual, thermal, olfactory, or similar information</td>
                <td>Images and audio, video or call recordings created in connection with our business activities</td>
                <td>NO</td>
                </tr>
                <tr>
                <td>H. Professional or employment-related information</td>
                <td>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
                <td>NO</td>
                </tr>
                <tr>
                <td>I. Education Information</td>
                <td>Student records and directory information</td>
                <td>NO</td>
                </tr>
                <tr>
                <td>J. Inferences drawn from collected personal information</td>
                <td>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual&rsquo;s preferences and characteristics</td>
                <td>NO</td>
                </tr>
                <tr>
                <td>K. Sensitive personal information</td>
                <td>&nbsp;</td>
                <td>NO</td>
                </tr>
                </tbody>
                </table>
                </div>
                <div>
                <table>
                <tbody>
                <tr>
                <td>L. Personal information as defined in the California Customer Records statute</td>
                <td>Name, contact information, education, employment, employment history, and financial information</td>
                <td>&nbsp;NO</td>
                </tr>
                </tbody>
                </table>
                </div>
                <p>We will use and retain the collected personal information as needed to provide the Services or for:</p>

                <ul>
                <li>A. Identifiers - As long as the user has an account with us</li>
                </ul>

                <ul>
                <li>F. Geolocation data - As long as the user has an account with us</li>
                </ul>

                <p>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</p>

                <ul>
                <li>Receiving help through our customer support channels;</li>
                </ul>

                <ul>
                <li>Participation in customer surveys or contests; and</li>
                </ul>

                <ul>
                <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                </ul>

                <p><strong>How do we use and share your personal information?</strong></p>

                <p>Learn about how we use your personal information in the section, 'HOW DO WE PROCESS YOUR INFORMATION?'</p>

                <p>More information about our data collection and sharing practices can be found in this privacy notice.</p>

                <p><strong>Will your information be shared with anyone else?</strong></p>

                <p>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about who we disclose personal information to in the section, 'WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?'</p>

                <p>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be 'selling' of your personal information.</p>

                <p>We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.</p>

                <p><strong>California Residents</strong></p>

                <p>California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>

                <p>If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).</p>

                <p><u><strong>CCPA Privacy Notice</strong></u></p>

                <p>The California Code of Regulations defines a 'resident' as:</p>

                <p>(1) every individual who is in the State of California for other than a temporary or transitory purpose and</p>

                <p>(2) every individual who is domiciled in the State of California who is outside of the State of California for a temporary or transitory purpose</p>

                <p>All other individuals are defined as 'non-residents'.</p>

                <p>If this definition of 'resident' applies to you, we must adhere to certain rights and obligations regarding your personal information.</p>

                <p><strong>Your rights with respect to your personal data</strong></p>

                <p><u>Right to request deletion of the data &mdash; Request to delete</u></p>

                <p>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</p>

                <p><u>Right to be informed &mdash; Request to know</u></p>

                <p>Depending on the circumstances, you have a right to know:</p>

                <ul>
                <li>whether we collect and use your personal information;</li>
                </ul>

                <ul>
                <li>the categories of personal information that we collect;</li>
                </ul>

                <ul>
                <li>the purposes for which the collected personal information is used;</li>
                </ul>

                <ul>
                <li>whether we sell or share personal information to third parties;</li>
                </ul>

                <ul>
                <li>the categories of personal information that we sold, shared, or disclosed for a business purpose;</li>
                </ul>

                <ul>
                <li>the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;</li>
                </ul>

                <ul>
                <li>the business or commercial purpose for collecting, selling, or sharing personal information; and</li>
                </ul>

                <ul>
                <li>the specific pieces of personal information we collected about you.</li>
                </ul>

                <p>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</p>

                <p><u>Right to Non-Discrimination for the Exercise of a Consumer&rsquo;s Privacy Rights</u></p>

                <p>We will not discriminate against you if you exercise your privacy rights.</p>

                <p><u>Right to Limit Use and Disclosure of Sensitive Personal Information</u></p>

                <p>We do not process consumer's sensitive personal information.</p>

                <p><u>Verification process</u></p>

                <p>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</p>

                <p>We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</p>

                <p><u>Other privacy rights</u></p>

                <ul>
                <li>You may object to the processing of your personal information.</li>
                </ul>

                <ul>
                <li>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</li>
                </ul>

                <ul>
                <li>You can designate an authorised agent to make a request under the CCPA on your behalf. We may deny a request from an authorised agent that does not submit proof that they have been validly authorised to act on your behalf in accordance with the CCPA.</li>
                </ul>

                <ul>
                <li>You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.</li>
                </ul>

                <p>To exercise these rights, you can contact us by visiting <a href="https://antslive.com" target="_blank" rel="noopener">https://antslive.com</a>, by email at <a href="mailto:account@PBPMLL0012.com">account@PBPMLL0012.com</a>, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.</p>

                <p><strong>Virginia Residents</strong></p>

                <p>Under the Virginia Consumer Data Protection Act (VCDPA):</p>

                <p>'Consumer' means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.</p>

                <p>'Personal data' means any information that is linked or reasonably linkable to an identified or identifiable natural person. 'Personal data' does not include de-identified data or publicly available information.</p>

                <p>'Sale of personal data' means the exchange of personal data for monetary consideration.</p>

                <p>If this definition 'consumer' applies to you, we must adhere to certain rights and obligations regarding your personal data.</p>

                <p><u>Your rights with respect to your personal data</u></p>

                <ul>
                <li>Right to be informed whether or not we are processing your personal data</li>
                </ul>

                <ul>
                <li>Right to access your personal data</li>
                </ul>

                <ul>
                <li>Right to correct inaccuracies in your personal data</li>
                </ul>

                <ul>
                <li>Right to request deletion of your personal data</li>
                </ul>

                <ul>
                <li>Right to obtain a copy of the personal data you previously shared with us</li>
                </ul>

                <ul>
                <li>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ('profiling')</li>
                </ul>

                <p><u>Exercise your rights provided under the Virginia VCDPA</u></p>

                <p>You may contact us by email <a href="mailto:account@PBPMLL0012.com">account@PBPMLL0012.com</a> or visit <a href="https://antslive.com" target="_blank" rel="noopener">https://antslive.com</a>.</p>

                <p>If you are using an authorised agent to exercise you rights, we may deny a request if the authorised agent does not submit proof that they have been validly authorised to act on your behalf.</p>

                <p><u>Verification process</u></p>

                <p>We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an authorised agent, we may need to collect additional information to verify your identity before processing your request.</p>

                <p>Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.</p>

                <p><u>Right to appeal</u></p>

                <p>If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at <a href="mailto:account@PBPMLL0012.com">account@PBPMLL0012.com</a>. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal if denied, you may contact the <a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint" target="_blank" rel="noopener">Attorney General to submit a complaint</a>.</p>

                <p><strong>Colorado Residents</strong></p>

                <p>This section applies only to Colorado residents. Under the Colorado Privacy Act (CPA), you have the rights listed below. However, these rights are not absolute, and in certain cases we may decline your request as permitted by law.</p>

                <ul>
                <li>Right to be informed whether or not we are processing your personal data</li>
                </ul>

                <ul>
                <li>Right to access your personal data</li>
                </ul>

                <ul>
                <li>Right to correct inaccuracies in your persona data</li>
                </ul>

                <ul>
                <li>Right to request deletion of your personal data</li>
                </ul>

                <ul>
                <li>Right to obtain a copy of the personal data you previously shared with us</li>
                </ul>

                <ul>
                <li>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that product legal or similarly significant effects ('profiling')</li>
                </ul>

                <p>To submit a request to exercise these rights described above, please email <a href="mailto:account@PBPMLL0012.com">account@PBPMLL0012.com</a> or visit <a href="https://antslive.com" target="_blank" rel="noopener">https://antslive.com</a>.</p>

                <p>If we decline to take action regarding your request and you wish to appeal our decision, please email us at <a href="mailto:account@PBPMLL0012.com">account@PBPMLL0012.com</a>. Within forty-five (45) days of receipt of an appeal, we will information you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.</p>

                <p><strong>Connecticut Residents</strong></p>

                <p>This section applies to Connecticut residents. Under the Connecticut Data Privacy Act (CTDPA), you have the rights listed below. However, these rights are not absolute, and in certain cases we may decline your request as permitted by law.</p>

                <ul>
                <li>Right to be informed whether or not we are processing your personal data</li>
                </ul>

                <ul>
                <li>Right to access your personal data</li>
                </ul>

                <ul>
                <li>Right to correct inaccuracies in your personal data</li>
                </ul>

                <ul>
                <li>Right to request deletion of your personal data</li>
                </ul>

                <ul>
                <li>Right to obtain a copy of the personal data you previously shared with us</li>
                </ul>

                <ul>
                <li>Right to opt out of the processing of your personal data if it is used from targeted advertising, the sale of personal data, or profiling in furtherance of decisions that product legal or similarly significant effects ('profiling')</li>
                </ul>

                <p>To submit a request to exercise these rights described above, please email <a href="mailto:account@PBPMLL0012.com">account@PBPMLL0012.com</a> or visit <a href="https://antslive.com" target="_blank" rel="noopener">https://antslive.com</a>.</p>

                <p>If we decline to take action regarding your request and you wish to appeal our decision, please email us at <a href="mailto:account@PBPMLL0012.com">account@PBPMLL0012.com</a>. Within sixty (60) days of receipt of an appeal, we will information you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.</p>

                <h2>11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>

                <p><strong><em>In Short: </em></strong><em>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></p>

                <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>

                <h2>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>

                <p>If you have questions or comments about this notice, you may email us at <a href="mailto:account@PBPMLL0012.com">account@PBPMLL0012.com</a>. or contact us by post at:</p>

                <p>PBPMLL0012 LIMITED</p>

                <p>10 Redchurch Street</p>

                <p>London E2 7DD</p>

                <p>United Kingdom</p>

                <h2>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>

                <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit: <a href="https://antslive.com" target="_blank" rel="noopener">https://antslive.com</a>.</p>
        </div>
    );
}

export default DisclaimerView