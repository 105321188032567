import React from 'react';
import '../styles/trash-view.scss';

import barcode from '../images/barcode.png';
import trashIcon from '../images/trash.png';

const TrashView = ({}) => {

    return (
        <div className='trash-view-container'>

            <div className='side-container'>
                    
                <div className='trash-icon'>
                    <img src={trashIcon} alt='Trash Icon' />
                    <p>Trash</p>
                </div>                      
            </div>

            <div className='trash-icon link'>
                <img src={barcode} alt='Barcode Button' />
                <p>untitled.png</p>
            </div>  

        </div>
    );
}

export default TrashView