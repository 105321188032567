import React, { useEffect, useState } from 'react';
import '../styles/security-cam-view.scss';

import cameraTop from '../images/camera-top.png';
import cameraBottom from '../images/camera-bottom.png';

import gsap from 'gsap';

const SecurityCamView = ({}) => {

    useEffect(() => {
        let ctx = gsap.context(() => {
            initMouseTracker();
        });

        return () => {
            ctx.revert();
            removeMouseTracker();
        };
    }, [])           

    function calcAngleDegrees(x, y) {
        return (Math.atan2(y, x) * 180) / Math.PI;
    }    

    const trackMouse = (e) => {
        //camera is at -> 0 at -27deg

        const position = {x: e.clientX, y: e.clientY};
        const cameraHeight = window.innerHeight / 2;
        const cameraWidth = cameraHeight * 1.3447;
        const cameraOrigin = {x: cameraWidth * 0.4471, y: cameraHeight * 0.5368}

        const newAngle = calcAngleDegrees(position.x - cameraOrigin.x, position.y - cameraOrigin.y) - 27;

        gsap.to('.camera-top', { rotation: newAngle});
    };

    const initMouseTracker = () => {
        window.addEventListener('mousemove', trackMouse);
    };

    const removeMouseTracker = () => {
        window.removeEventListener('mousemove', trackMouse);
    };

    return (
        <div className='security-cam-view-container'>
            <img className='camera-bottom' src={cameraBottom}  alt='Security Camera Bottom' />
            <img className='camera-top' src={cameraTop} alt='Security Camera Top' />            
        </div>
    );
}

export default SecurityCamView