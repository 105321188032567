import React from 'react';
import '../styles/how-to-view.scss';

const HowToView = ({}) => {

    return (
        <div className='how-to-view-container' data-clickable>
            <p>How to use:<br /><br />
                Find camera.<br />
                Perform.<br />
                Record.
                </p>
            
        </div>
    );
}

export default HowToView