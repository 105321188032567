import React from 'react';
import '../styles/loader.scss';

import Typewriter from 'typewriter-effect';

const Loader = ({setTextReady}) => {
    const typeFunction = (typewriter) => {

        typewriter.typeString('Thousands of leaked UK CCTV cameras can be watched online.')
        .pauseFor(500)
        .typeString('<br />Anytime.')
        .pauseFor(500)
        .typeString('<br />Anywhere.')
        .pauseFor(500)        
        .typeString('<br />For Anything.')
        .pauseFor(500)        
        .callFunction(() => {
            setTextReady(true);
        })           
        // .changeDelay(1000)
        // .typeString('............................................................')
        .start();

    };


    return (
        <div className='loader-container'>

            <p className='title'>AntsLive Cams</p>
            
            <Typewriter
                onInit={typeFunction}
                options={{
                    wrapperClassName: 'type-out',
                    delay: 25,
                }}
                />

        </div>
    );
}

export default Loader