import React, { useRef, useEffect, useState } from 'react'

import './styles/globals.scss';

import Loader from './components/loader';
import BackgroundsView from './components/backgrounds';
import AppWindow from './components/app-window';
import MapView from './components/map-view';
import CamListView from './components/cam-list-view';
import MusicVideoView from './components/music-video-view';
import HowToView from './components/how-to-view';
import DisclaimerView from './components/disclaimer-view';
import VirusView from './components/virus-view';
import TrashView from './components/trash-view';
import SecurityCamView from './components/security-cam-view';

import documentIcon from './images/document.png';
import virusIcon from './images/virus.png';
import trashIcon from './images/trash.png';

const App = () => {
  const pageContainer = useRef(null); 
  const mapView = useRef(null);  

  const [textReady, setTextReady] = useState(false);
  const [section1Loaded, setSection1Loaded] = useState(false);  
  const [backgroundSectionLoaded, setBackgroundSectionLoaded] = useState(false);
  const [section2Loaded, setSection2Loaded] = useState(false);
  const [minimiseAll, setMinimiseAll] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [disclaimerVisible, setDisclaimerVisible] = useState(false);
  const [virusVisible, setVirusVisible] = useState(false);
  const [trashVisible, setTrashVisible] = useState(false);      

  const isMobileSize = window.innerWidth < 550 ? true : false;

  const hideLoader = () => {
   setTimeout(()=> {
        pageContainer.current.style = {};
        setIsLoading(false);
    }, 1000);
  }

  const checkSectionsLoaded = () => {

    if(textReady && backgroundSectionLoaded && section1Loaded && section2Loaded) {
      return true;
    } else {
      return false;
    }

  };

  useEffect(() => {
    const hasLoaded = checkSectionsLoaded();

    if(hasLoaded) {            
      hideLoader();
    }

  }, [backgroundSectionLoaded, textReady, section1Loaded, section2Loaded]);
  
  return (
    <main ref={pageContainer} style={{overflow: 'hidden'}}>

      <BackgroundsView setSectionLoaded={setBackgroundSectionLoaded} />

      <div className='desktop-icon-container'>

        <div className='desktop-icon' onClick={() => { setDisclaimerVisible(true) }}>
          <img src={documentIcon} alt='Document Icon Button' />
          <p>Disclaimer.txt</p>
        </div>  

        <div className='desktop-icon' onClick={() => { setVirusVisible(true) }}>
          <img src={virusIcon} alt='Virus Icon Button' />
          <p>Virus.exe</p>
        </div>  

        <div className='desktop-icon' onClick={() => { setTrashVisible(true) }}>
          <img src={trashIcon} alt='Trash Icon Button' />
          <p>Trash</p>
        </div>                        
        
      </div>

      <AppWindow
        containerId={4}    
        title={'How_View'}        
        content={<HowToView />}
        initialStyles={{
          height: '20%',
          width: '10%',
          top: '2%',
          left: '65%'          
        }}    
        minimiseAll={minimiseAll}
        setMinimiseAll={setMinimiseAll}       
      />

      <AppWindow
        containerId={3}
        title={'Top_Cam_View'} 
        content={<CamListView setSectionLoaded={setSection1Loaded} />}
        initialStyles={{
          height: '60%',
          width: '40%',
          top: '35%',
          left: '50%',   
        }}    
        minimiseAll={minimiseAll}
        setMinimiseAll={setMinimiseAll}              
      />       

      <AppWindow
        containerId={2}
        title={'Map_View'}
        content={<MapView ref={mapView} setSectionLoaded={setSection2Loaded} isLoading={isLoading} />}
        initialStyles={{
          height: '70%',
          width: '65%',
          top: '10%',
          left: '10%'          
        }}
        onResize={() => {
          mapView.current.map.current.resize();
        }}        
        minimiseAll={minimiseAll}
        setMinimiseAll={setMinimiseAll}
      />  
      
      <AppWindow
        containerId={1}    
        title={'AntsLive_View'}        
        content={<MusicVideoView setSectionLoaded={setSection1Loaded} />}
        initialStyles={{
          height: '40%',
          width: '35%',
          top: '50%',
          left: '7%'          
        }}    
        minimiseAll={minimiseAll}
        setMinimiseAll={setMinimiseAll}               
      />
     

      {!isMobileSize &&
            <SecurityCamView />
      }

      {disclaimerVisible && 
        (
          <AppWindow
          containerId={5}
          title={'Disclaimer_View'} 
          content={<DisclaimerView />}
          initialStyles={{
            height: '40%',
            width: '30%',
            top: '15%',
            left: '60%',   
          }}    
          minimiseAll={minimiseAll}
          setMinimiseAll={setMinimiseAll}     
          onClose={() => {setDisclaimerVisible(false)}}       
        />             
        )
      } 

      {virusVisible && 
        (
          <AppWindow
          containerId={6}
          title={'Virus_View'} 
          content={<VirusView />}
          initialStyles={{
            height: '40%',
            width: '30%',
            top: '15%',
            left: '60%',   
          }}    
          minimiseAll={minimiseAll}
          setMinimiseAll={setMinimiseAll}     
          onClose={() => {setVirusVisible(false)}}       
        />             
        )
      } 

      {trashVisible && 
        (
          <AppWindow
          containerId={7}
          title={'Trash_View'} 
          content={<TrashView />}
          initialStyles={{
            height: '40%',
            width: '30%',
            top: '15%',
            left: '60%',   
          }}    
          minimiseAll={minimiseAll}
          setMinimiseAll={setMinimiseAll}     
          onClose={() => {setTrashVisible(false)}}       
        />             
        )
      }             
      
      {isLoading && 
        (<Loader setTextReady={setTextReady} />)
      }
    </main>
  )
}

export default App