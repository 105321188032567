import React from 'react';
import '../styles/virus-view.scss';

import Webcam from "react-webcam";

const VirusView = ({}) => {

    return (
        <div className='virus-view-container' data-clickable>            
            <Webcam />
            
            <div className='live-container'>
                <div className='dot'></div>
                <p>LIVE</p>
            </div>
        </div>
    );
}

export default VirusView