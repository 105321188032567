import React from 'react';
import '../styles/music-video-view.scss';


const MusicVideoView = ({setSectionLoaded}) => {

    return (
        <div className='music-video-view-container'>
            <video className='music-video' src='https://res.cloudinary.com/dgnw8kdwe/video/upload/q_50/v1706124628/mother/antslive/video_z4lrp6.mp4' muted autoPlay playsInline controls loop 
                    onCanPlayThrough={() => { setSectionLoaded(true)}} />
        </div>
    );
}

export default MusicVideoView