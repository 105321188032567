import React, { useEffect, useState } from 'react';
import '../styles/backgrounds.scss';

import background1 from '../videos/backgrounds/background-1.mp4';
import background2 from '../videos/backgrounds/background-2.mp4';
import background3 from '../videos/backgrounds/background-3.mp4';
import background4 from '../videos/backgrounds/background-4.mp4';
import ticker from '../videos/backgrounds/ticker.mp4';

import background from '../images/background.jpg';

const BackgroundsView = ({setSectionLoaded}) => {
    const [currentBackground, setCurrentBackground] = useState(1);

    const numberOfBackgrounds = 5;
    const timeBetween = 20000;

    const videoLoaded = () => {
        setSectionLoaded(true);
    };

    useEffect(() => {

        const backgroundInterval = setInterval(() => {
            if(currentBackground === numberOfBackgrounds) {
                setCurrentBackground(1);
            } else {
                setCurrentBackground(currentBackground + 1);
            }
            
        }, timeBetween);

        return () => {
            clearInterval(backgroundInterval);
        }

    }, [currentBackground]);

    return (
        <div className='backgrounds-container'>

            {currentBackground === 1 &&
                <video className='background' src={background1} onCanPlayThrough={videoLoaded} autoPlay playsInline muted loop />
            }

            {currentBackground === 2 &&
                <video className='background' src={background2} autoPlay playsInline muted loop />
            }

            {currentBackground === 3 &&
                <video className='background' src={background3} autoPlay playsInline muted loop />
            }

            {currentBackground === 4 &&
                <video className='background' src={background4} autoPlay playsInline muted loop />
            }

            {currentBackground === 5 &&
                <img className='background' src={background} alt='AntsLiveCams Background' />
            }              

            <video className='ticker' src={ticker} autoPlay playsInline muted loop />                                      

        </div>
    );
}

export default BackgroundsView