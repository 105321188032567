import React, { useEffect } from 'react';
import '../styles/app-window.scss';

import $ from 'jquery';
import 'jquery-ui/ui/widgets/resizable';

import { gsap } from 'gsap';

import { Draggable } from "gsap/Draggable";

gsap.registerPlugin(Draggable);

const AppWindow = ({title, content, initialStyles, containerId, onResize, minimiseAll, setMinimiseAll, onClose}) => {

    const isMobileSize = window.innerWidth < 550 ? true : false;

    const windowNumber1 = 64 * Math.floor(Math.random() * 20);
    const windowNumber2 = 64 * Math.floor(Math.random() * 20);

    const initDraggable = () => {
        // the ui-resizable-handles are added here
        $(`#window-${containerId}`).resizable({
            handles: 'all',
            resize: () => {
                if(typeof onResize === 'function') {                 
                    onResize();
                }
            },
        });

        // makes GSAP Draggable avoid clicks on the resize handles
        $('.ui-resizable-handle').attr('data-clickable', true);
        
        Draggable.create(`#window-${containerId}`, {
            dragClickables: false
        });
    };

    const minimise = () => {
        if(!isMobileSize) {
            $(`#window-${containerId}`).css({
                height: '0px',
                width: '15%',
                left: `${(containerId - 1)* 15}%`,     
                top: '95%',       
                overflow: 'hidden',
            });

        } else {

            $(`#window-${containerId}`).css({
                height: '0px',
                width: '96%',
                top: `${100 - (containerId * 6)}%`,     
                left: '2%',       
                overflow: 'hidden',
            });


        }

        gsap.set(`#window-${containerId}`, {x:0, y:0});     

        if(typeof onResize === 'function') {                 
            onResize();
        }          
    };

    const fullScreen = () => {

        if(!isMobileSize) {
            $(`#window-${containerId}`).css({
                height: '90%',
                width: '98%',
                left: '1%',
                top: '1%',
                overflow: 'initial',
            });

        } else {

            minimiseAll.map((minimise) => {
                minimise();
                return null;
            });

            $(`#window-${containerId}`).css({
                height: '70%',
                width: '96%',
                left: '2%',
                top: '2%',
                overflow: 'initial',
            });
        }

        gsap.set(`#window-${containerId}`, {x:0, y:0});        

        if(typeof onResize === 'function') {                 
            onResize();
        }            
    };
    
    const close = () => {

        if(typeof onClose === 'function') {                 
            onClose();
        } else {
            $(`#window-${containerId}`).remove();
        }                    
    };    

    useEffect(() => {
        let ctx = gsap.context(() => {
            if(!isMobileSize) {
                initDraggable();
            }

            const newArr = minimiseAll;
            newArr.push(minimise);

            setMinimiseAll(newArr);

            if(isMobileSize && (containerId !== 1  && containerId <= 4)) {
                minimise();
            }
        });

        return () => ctx.revert();
    }, []);  

    return (
        <div id={`window-${containerId}`} className='app-window-container' style={!isMobileSize ? {...initialStyles} : {height: '70%', width: '96%', top: '2%', left: '2%'}}>
            <div className='app-window-header'>
                <p className='app-window-title'>
                    Slot: {title} | Speed 100% | PAL | Limiter: Normal | 65dx OGL HW | {windowNumber1}x{windowNumber2} | Automatic
                </p>
                <button className='header-button minimise-button' onClick={minimise}></button>
                <button className='header-button fullscreen-button'onClick={fullScreen}></button>
                <button className='header-button close-button'onClick={close}></button>
            </div>

            {typeof content !== 'undefined' &&
                (content)
            }

        </div>
    );
}

export default AppWindow