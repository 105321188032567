import React, { useEffect } from 'react';
import '../styles/cam-list-view.scss';

import data from '../data/top-cameras';

const CamListView  = ({setSectionLoaded}) => {

    useEffect(() => {
       // setSectionLoaded(true);
    });

    const addVideoList = () => {
       // const startingPoint = Math.floor(Math.random()*(data.cameras.length - 20));
        //let selectedCameras = data.cameras.slice(0, 20);
        let selectedCameras = data.cameras;

        // add markers to map
        return selectedCameras.map((camera, index) => {

            const variation = Math.floor(Math.random() * 3)

            return(
                <div className={`camera-container camera-container-${variation}`} key={index} data-clickable>
                    <a href={camera.videoURL} target='_blank'>
                        <h3>{camera.location.lat.toFixed(4)}, {camera.location.lng.toFixed(4)}</h3>
                        <img src={camera.imageURL} />
                        <p>{camera.name}</p>
                        {/* <video src={camera.videoURL} autoplay muted playsinline loop /> */}
                    </a>  
                </div>              
            )
        });        

    };

    return (
        <div className='cam-list-view-container' data-clickable>
            { addVideoList() }
        </div>
    );
};

export default CamListView